<template>
  <v-container>
    <DashboardToolBar class="mb-4"></DashboardToolBar>
    <v-row align="start" justify="start" class="d-flex" v-if="gameSessionsExist">
      <session-card
        v-for="session in gameSessions"
        :key="session.id"
        :session="session"
        @end-session="showEndDialog"
        @delete-session="showDialog"
        @view-details="getData"
      />
    </v-row>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title> Are you sure? </v-card-title>
        <v-card-text>
          All participant details and score details for this session will be deleted. This action is not reversible!. Do
          you really want to delete this session! ?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="error" @click="deleteSession"> DELETE </v-btn>
          <v-btn outlined text color="accent" @click="dialog = false"> CANCEL </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="enddialog" width="500">
      <v-card>
        <v-card-title> Are you sure? </v-card-title>
        <v-card-text>
          Players will not be able to join this session anymore. This action is not reversible!. Do you really want to
          end this session! ?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="error" @click="endSession"> End Session </v-btn>
          <v-btn outlined text color="accent" @click="enddialog = false"> CANCEL </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState, mapMutations } from "vuex"
import { getAllSessions, deleteSession } from "@/api/QuizApi"
import { updateGameSession } from "@/api/GameApi"
import SessionCard from "@/components/quiz/SessionCard"
import DashboardToolBar from "@/components/quiz/DashboardToolBar"

export default {
  name: "session-list",
  data() {
    return {
      sessionref: null,
      docid: null,
      dialog: false,
      enddialog: false,
      currentsession: null,
    }
  },
  components: {
    SessionCard,
    DashboardToolBar,
  },
  created() {
    getAllSessions(this.$route.params.id, this.user.uid)
  },

  beforeDestroy() {
    this.clearSession()
  },

  computed: {
    ...mapState({
      gameSessions: (state) => state.quiz.gameSessions,
      user: (state) => state.user.currentUser,
    }),

    gameSessionsExist() {
      return this.gameSessions?.length
    },
  },

  methods: {
    ...mapMutations({ showAlert: "SHOW_SNACKBAR", clearSession: "CLEAR_SESSIONS_DATA" }),

    getData(session) {
      const snacktext = "Get session list successfully!"
      this.setSession(session)
      this.$router.push({ name: "session-data" })
      this.showAlert(snacktext)
    },

    showDialog(docid) {
      this.docid = docid
      this.dialog = true
    },

    showEndDialog(session) {
      this.currentsession = session
      this.enddialog = true
    },

    async endSession() {
      await updateGameSession(this.currentsession, { end: true })
      this.enddialog = false
      const snacktext = "Session Ended"
      this.showAlert(snacktext)
    },

    async deleteSession() {
      this.dialog = false
      await deleteSession(this.$route.params.id, this.docid)
      const snacktext = "Session Deleted"
      this.showAlert(snacktext)
    },

    goBack() {
      this.$router.go(-1)
    },
  },

  filters: {
    humanizeSession: function (value) {
      const session_type = { S: "Simple", L: "Live", C: "Classic" }
      return session_type[value]
    },

    humanizeDate: function (date) {
      return date
        ? new Date(date.seconds * 1000).toLocaleString("en-US", {
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
          })
        : ""
    },
  },
}
</script>
<style lang="scss" scoped></style>
