var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("DashboardToolBar", { staticClass: "mb-4" }),
      _vm.gameSessionsExist
        ? _c(
            "v-row",
            {
              staticClass: "d-flex",
              attrs: { align: "start", justify: "start" }
            },
            _vm._l(_vm.gameSessions, function(session) {
              return _c("session-card", {
                key: session.id,
                attrs: { session: session },
                on: {
                  "end-session": _vm.showEndDialog,
                  "delete-session": _vm.showDialog,
                  "view-details": _vm.getData
                }
              })
            }),
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { width: "500" },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v(" Are you sure? ")]),
              _c("v-card-text", [
                _vm._v(
                  " All participant details and score details for this session will be deleted. This action is not reversible!. Do you really want to delete this session! ? "
                )
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", color: "error" },
                      on: { click: _vm.deleteSession }
                    },
                    [_vm._v(" DELETE ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { outlined: "", text: "", color: "accent" },
                      on: {
                        click: function($event) {
                          _vm.dialog = false
                        }
                      }
                    },
                    [_vm._v(" CANCEL ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "500" },
          model: {
            value: _vm.enddialog,
            callback: function($$v) {
              _vm.enddialog = $$v
            },
            expression: "enddialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v(" Are you sure? ")]),
              _c("v-card-text", [
                _vm._v(
                  " Players will not be able to join this session anymore. This action is not reversible!. Do you really want to end this session! ? "
                )
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", color: "error" },
                      on: { click: _vm.endSession }
                    },
                    [_vm._v(" End Session ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { outlined: "", text: "", color: "accent" },
                      on: {
                        click: function($event) {
                          _vm.enddialog = false
                        }
                      }
                    },
                    [_vm._v(" CANCEL ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }