<template>
  <v-col cols="12" md="6" xl="4">
    <v-hover v-slot="{ hover }">
      <v-card class="pa-4 px-lg-6 py-lg-4 session-card" :elevation="hover ? 16 : 0">
        <v-card-subtitle class="subtitle-1 font-weight-medium">
          {{ session.createdAt | humanizeDate }}
          <v-chip label color="accent2" class="secondary--text font-weight-bold align-self-end mx-2">{{
            session.type | humanizeSession
          }}</v-chip>
          <v-chip label color="accent2" class="secondary--text font-weight-bold align-self-end mx-2">{{
            session.quizpin
          }}</v-chip>
          <v-chip
            label
            :color="active ? 'success' : 'error'"
            :class="active ? 'white' : 'secondary--text'"
            class="font-weight-bold align-self-end mx-2"
            >{{ active ? "Ongoing" : "Ended" }}</v-chip
          >
        </v-card-subtitle>
        <v-card-title class="text-h5 font-weight-bold linevisibility">{{ session.title }} </v-card-title>
        <v-card-subtitle class="subtitle-1 mt-1 font-weight-medium"> {{ session.sessionname }}</v-card-subtitle>

        <v-card-actions>
          <v-btn depressed color="success" @click="goToSessionData">
            <font-awesome-icon icon="clipboard-list" pull="left"></font-awesome-icon>
            View Details</v-btn
          >
          <v-btn outlined color="secondary" @click="endSession" v-if="active">
            <font-awesome-icon icon="stop" pull="left"></font-awesome-icon>
            End Session</v-btn
          >
          <v-btn outlined color="error" @click="deleteSession" v-if="!active">
            <font-awesome-icon icon="trash-alt" pull="left"></font-awesome-icon>
            Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-hover>
  </v-col>
</template>

<script>
import { mapActions } from "vuex"
export default {
  name: "SessionCard",
  props: ["session"],
  computed: {
    active() {
      return !this.session.end
    },
  },
  methods: {
    ...mapActions(["setSession"]),
    deleteSession() {
      this.$emit("delete-session", this.session.id)
    },

    endSession() {
      this.$emit("end-session", this.session)
    },

    goToSessionData() {
      this.setSession(this.session)
      this.$router.push({ name: "session-data", params: { quizid: this.session.quizid, id: this.session.sessionid } })
    },
  },
  filters: {
    humanizeSession: function (value) {
      const session_type = { S: "Simple", L: "Live", C: "Classic" }
      return session_type[value]
    },

    humanizeDate: function (date) {
      return date
        ? new Date(date.seconds * 1000).toLocaleString("en-US", {
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
          })
        : ""
    },
  },
}
</script>

<style lang="scss" scoped>
.linevisibility {
  line-height: 1.5rem;
  height: 3rem;
  overflow: hidden !important;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.session-card {
  // border: 1px solid white;
  border: 1px solid var(--v-accent2-base);
  border-left: 0.8rem solid var(--v-accent2-base);
  &:hover {
    border: 1px solid var(--v-secondary-base);
    border-left: 0.8rem solid var(--v-secondary-base);
  }
}
</style>
