var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    { attrs: { cols: "12", md: "6", xl: "4" } },
    [
      _c("v-hover", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var hover = ref.hover
              return [
                _c(
                  "v-card",
                  {
                    staticClass: "pa-4 px-lg-6 py-lg-4 session-card",
                    attrs: { elevation: hover ? 16 : 0 }
                  },
                  [
                    _c(
                      "v-card-subtitle",
                      { staticClass: "subtitle-1 font-weight-medium" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("humanizeDate")(_vm.session.createdAt)
                            ) +
                            " "
                        ),
                        _c(
                          "v-chip",
                          {
                            staticClass:
                              "secondary--text font-weight-bold align-self-end mx-2",
                            attrs: { label: "", color: "accent2" }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("humanizeSession")(_vm.session.type)
                              )
                            )
                          ]
                        ),
                        _c(
                          "v-chip",
                          {
                            staticClass:
                              "secondary--text font-weight-bold align-self-end mx-2",
                            attrs: { label: "", color: "accent2" }
                          },
                          [_vm._v(_vm._s(_vm.session.quizpin))]
                        ),
                        _c(
                          "v-chip",
                          {
                            staticClass: "font-weight-bold align-self-end mx-2",
                            class: _vm.active ? "white" : "secondary--text",
                            attrs: {
                              label: "",
                              color: _vm.active ? "success" : "error"
                            }
                          },
                          [_vm._v(_vm._s(_vm.active ? "Ongoing" : "Ended"))]
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-card-title",
                      {
                        staticClass: "text-h5 font-weight-bold linevisibility"
                      },
                      [_vm._v(_vm._s(_vm.session.title) + " ")]
                    ),
                    _c(
                      "v-card-subtitle",
                      { staticClass: "subtitle-1 mt-1 font-weight-medium" },
                      [_vm._v(" " + _vm._s(_vm.session.sessionname))]
                    ),
                    _c(
                      "v-card-actions",
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { depressed: "", color: "success" },
                            on: { click: _vm.goToSessionData }
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: "clipboard-list", pull: "left" }
                            }),
                            _vm._v(" View Details")
                          ],
                          1
                        ),
                        _vm.active
                          ? _c(
                              "v-btn",
                              {
                                attrs: { outlined: "", color: "secondary" },
                                on: { click: _vm.endSession }
                              },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: "stop", pull: "left" }
                                }),
                                _vm._v(" End Session")
                              ],
                              1
                            )
                          : _vm._e(),
                        !_vm.active
                          ? _c(
                              "v-btn",
                              {
                                attrs: { outlined: "", color: "error" },
                                on: { click: _vm.deleteSession }
                              },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: "trash-alt", pull: "left" }
                                }),
                                _vm._v(" Delete")
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }